import { graphql } from 'gatsby'
import React from 'react'

import animation from '../../assets/blog/17/1.gif'
import webImg from '../../assets/blog/17/2.png'
import mobileImg from '../../assets/blog/17/3.png'
import heroImg from '../../assets/blog/17/hero.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Báječná studentská léta" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Báječná studentská léta" />
        <ContentSubPage>
          <div className="w-3/4 mx-auto my-12">
            <img
              src={heroImg}
              alt="Jak přežít stavbu domu"
              className="object-cover w-full rounded-[20px]"
            />
          </div>
          <p>Jaký jste typ studenta? Máte svůj život pod kontrolou?</p>
          <ul className="my-3 list-none">
            <li>
              a) Naprosto. Moje jediná vada je, že jsem dokonale organizovaný
              perfekcionista.
            </li>
            <li>b) Občas mi něco ujede, ale jinak zvládám v pohodě.</li>
            <li>c) Tak tak stíhám.</li>
            <li>d) Cože? Pomoc!</li>
          </ul>
          <p>
            Která odpověď pro vás platí? Pokud c) nebo d), tak pro vás máme
            aspoň jednu dobrou zprávu. Nejste sami! Studentský život přináší
            totiž svou vlastní sadu velmi stresových faktorů, od nutnosti
            sebedisciplíny a organizace času při učení, finančních potíží,
            nároků na socializaci, po často křehké duševní zdraví. Přihoďme
            poměrně náročnou administrativu a logistiku a není divu, že se v tom
            často „topíme”.
          </p>
          <p>
            Studenti proto běžně využívají nejrůznější aplikace, které jim mají
            usnadnit život. Platí ale, že čím víc jich nainstalují, tím větší
            zmatek vzniká. Aplikace Flagis je specifická tím, že v sobě
            integruje hned několik zásadních funkcí organizace a komunikace, a
            přitom poskytuje maximální flexibilitu. To znamená, že padne jako
            ulitá nejrůznějším lidem s rozdílnými potřebami. Pro studenty tak
            vedle funkce organizéru, diáře a e-mailu nabízí i neocenitelné
            sdílení a ukládání dokumentů v různých formátech. Od nafocených
            přednášek, které jste nestihli, po videa nebo textové dokumenty.
            Organizér samotný pak díky systému štítků lehce zpřehlední nejen
            studijní povinnosti, ale i organizaci brigád, či radosti jako
            kulturní akce nebo sport. Celou strukturu kategorií si můžete
            vytvořit podle vaší myšlenkové mapy. Získáte tak vlastní jedinečné
            přehledné zobrazení úkolů a poznámek přesně podle vašich potřeb.
          </p>
          <p>
            Studentský život umí dát zabrat, ale může být i báječný. Jen to chce
            mít vše dobře zorganizované a pod kontrolou. Flagis je skvělý
            pomocník na dosah ruky.
          </p>

          <div className="my-5">
            <a href={animation} target="_blank" rel="noreferrer">
              <img
                src={animation}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={webImg} target="_blank" rel="noreferrer">
              <img
                src={webImg}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={mobileImg} target="_blank" rel="noreferrer">
              <img
                src={mobileImg}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
